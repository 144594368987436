import { default as indexo7FjlqWieSMeta } from "/opt/render/project/src/apps/frontend/pages/index.vue?macro=true";
import { default as bidsEGT4U8l50ZMeta } from "/opt/render/project/src/apps/frontend/pages/account/bids.vue?macro=true";
import { default as profile7l3D7CIEhNMeta } from "/opt/render/project/src/apps/frontend/pages/account/profile.vue?macro=true";
import { default as salesGqyGYq5EmrMeta } from "/opt/render/project/src/apps/frontend/pages/account/sales.vue?macro=true";
import { default as _91slug_93HyoRl6cTm4Meta } from "/opt/render/project/src/apps/frontend/pages/auction/[slug].vue?macro=true";
import { default as _91_46_46_46optionalParams_93GAuYBt56nZMeta } from "/opt/render/project/src/apps/frontend/pages/auctions/[localizedAssetType]/[...optionalParams].vue?macro=true";
import { default as cookie_45policyKtSfYJaJdeMeta } from "/opt/render/project/src/apps/frontend/pages/cookie-policy.vue?macro=true";
import { default as faqjLjP6Xq7LMMeta } from "/opt/render/project/src/apps/frontend/pages/faq.vue?macro=true";
import { default as password_45resetVf2vExmytjMeta } from "/opt/render/project/src/apps/frontend/pages/password-reset.vue?macro=true";
import { default as pricing_45buyer2hGrDrDoz3Meta } from "/opt/render/project/src/apps/frontend/pages/pricing-buyer.vue?macro=true";
import { default as pricing_45sellerStUodoLhdOMeta } from "/opt/render/project/src/apps/frontend/pages/pricing-seller.vue?macro=true";
import { default as privacy_45policy6IjMilWdgTMeta } from "/opt/render/project/src/apps/frontend/pages/privacy-policy.vue?macro=true";
import { default as confirmtfx7JtTocTMeta } from "/opt/render/project/src/apps/frontend/pages/signup/confirm.vue?macro=true";
import { default as indexDpTVRtyxWNMeta } from "/opt/render/project/src/apps/frontend/pages/signup/index.vue?macro=true";
import { default as terms_45and_45conditionszEnemuYX6KMeta } from "/opt/render/project/src/apps/frontend/pages/terms-and-conditions.vue?macro=true";
export default [
  {
    name: "index",
    path: "/",
    component: () => import("/opt/render/project/src/apps/frontend/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "account-bids___en",
    path: "/en/account/bids",
    meta: bidsEGT4U8l50ZMeta || {},
    component: () => import("/opt/render/project/src/apps/frontend/pages/account/bids.vue").then(m => m.default || m)
  },
  {
    name: "account-bids___it",
    path: "/it/account/bids",
    meta: bidsEGT4U8l50ZMeta || {},
    component: () => import("/opt/render/project/src/apps/frontend/pages/account/bids.vue").then(m => m.default || m)
  },
  {
    name: "account-profile___en",
    path: "/en/account/profile",
    meta: profile7l3D7CIEhNMeta || {},
    component: () => import("/opt/render/project/src/apps/frontend/pages/account/profile.vue").then(m => m.default || m)
  },
  {
    name: "account-profile___it",
    path: "/it/account/profile",
    meta: profile7l3D7CIEhNMeta || {},
    component: () => import("/opt/render/project/src/apps/frontend/pages/account/profile.vue").then(m => m.default || m)
  },
  {
    name: "account-sales___en",
    path: "/en/account/sales",
    meta: salesGqyGYq5EmrMeta || {},
    component: () => import("/opt/render/project/src/apps/frontend/pages/account/sales.vue").then(m => m.default || m)
  },
  {
    name: "account-sales___it",
    path: "/it/account/sales",
    meta: salesGqyGYq5EmrMeta || {},
    component: () => import("/opt/render/project/src/apps/frontend/pages/account/sales.vue").then(m => m.default || m)
  },
  {
    name: "auction-slug___en",
    path: "/en/auction/:slug()",
    meta: _91slug_93HyoRl6cTm4Meta || {},
    component: () => import("/opt/render/project/src/apps/frontend/pages/auction/[slug].vue").then(m => m.default || m)
  },
  {
    name: "auction-slug___it",
    path: "/it/asta/:slug()",
    meta: _91slug_93HyoRl6cTm4Meta || {},
    component: () => import("/opt/render/project/src/apps/frontend/pages/auction/[slug].vue").then(m => m.default || m)
  },
  {
    name: "auctions-localizedAssetType-optionalParams___en",
    path: "/en/auctions/:localizedAssetType()/:optionalParams(.*)*",
    meta: _91_46_46_46optionalParams_93GAuYBt56nZMeta || {},
    component: () => import("/opt/render/project/src/apps/frontend/pages/auctions/[localizedAssetType]/[...optionalParams].vue").then(m => m.default || m)
  },
  {
    name: "auctions-localizedAssetType-optionalParams___it",
    path: "/it/aste/:localizedAssetType()/:optionalParams(.*)*",
    meta: _91_46_46_46optionalParams_93GAuYBt56nZMeta || {},
    component: () => import("/opt/render/project/src/apps/frontend/pages/auctions/[localizedAssetType]/[...optionalParams].vue").then(m => m.default || m)
  },
  {
    name: "cookie-policy___en",
    path: "/en/cookie-policy",
    component: () => import("/opt/render/project/src/apps/frontend/pages/cookie-policy.vue").then(m => m.default || m)
  },
  {
    name: "cookie-policy___it",
    path: "/it/cookie-policy",
    component: () => import("/opt/render/project/src/apps/frontend/pages/cookie-policy.vue").then(m => m.default || m)
  },
  {
    name: "faq___en",
    path: "/en/faq",
    component: () => import("/opt/render/project/src/apps/frontend/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: "faq___it",
    path: "/it/faq",
    component: () => import("/opt/render/project/src/apps/frontend/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: "index___en",
    path: "/en",
    component: () => import("/opt/render/project/src/apps/frontend/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___it",
    path: "/it",
    component: () => import("/opt/render/project/src/apps/frontend/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "password-reset___en",
    path: "/en/password-reset",
    meta: password_45resetVf2vExmytjMeta || {},
    component: () => import("/opt/render/project/src/apps/frontend/pages/password-reset.vue").then(m => m.default || m)
  },
  {
    name: "password-reset___it",
    path: "/it/password-reset",
    meta: password_45resetVf2vExmytjMeta || {},
    component: () => import("/opt/render/project/src/apps/frontend/pages/password-reset.vue").then(m => m.default || m)
  },
  {
    name: "pricing-buyer___en",
    path: "/en/pricing-buyer",
    component: () => import("/opt/render/project/src/apps/frontend/pages/pricing-buyer.vue").then(m => m.default || m)
  },
  {
    name: "pricing-buyer___it",
    path: "/it/pricing-buyer",
    component: () => import("/opt/render/project/src/apps/frontend/pages/pricing-buyer.vue").then(m => m.default || m)
  },
  {
    name: "pricing-seller___en",
    path: "/en/pricing-seller",
    component: () => import("/opt/render/project/src/apps/frontend/pages/pricing-seller.vue").then(m => m.default || m)
  },
  {
    name: "pricing-seller___it",
    path: "/it/pricing-seller",
    component: () => import("/opt/render/project/src/apps/frontend/pages/pricing-seller.vue").then(m => m.default || m)
  },
  {
    name: "privacy-policy___en",
    path: "/en/privacy-policy",
    component: () => import("/opt/render/project/src/apps/frontend/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: "privacy-policy___it",
    path: "/it/privacy-policy",
    component: () => import("/opt/render/project/src/apps/frontend/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: "signup-confirm___en",
    path: "/en/signup/confirm",
    meta: confirmtfx7JtTocTMeta || {},
    component: () => import("/opt/render/project/src/apps/frontend/pages/signup/confirm.vue").then(m => m.default || m)
  },
  {
    name: "signup-confirm___it",
    path: "/it/signup/confirm",
    meta: confirmtfx7JtTocTMeta || {},
    component: () => import("/opt/render/project/src/apps/frontend/pages/signup/confirm.vue").then(m => m.default || m)
  },
  {
    name: "signup___en",
    path: "/en/signup",
    meta: indexDpTVRtyxWNMeta || {},
    component: () => import("/opt/render/project/src/apps/frontend/pages/signup/index.vue").then(m => m.default || m)
  },
  {
    name: "signup___it",
    path: "/it/signup",
    meta: indexDpTVRtyxWNMeta || {},
    component: () => import("/opt/render/project/src/apps/frontend/pages/signup/index.vue").then(m => m.default || m)
  },
  {
    name: "terms-and-conditions___en",
    path: "/en/terms-and-conditions",
    component: () => import("/opt/render/project/src/apps/frontend/pages/terms-and-conditions.vue").then(m => m.default || m)
  },
  {
    name: "terms-and-conditions___it",
    path: "/it/terms-and-conditions",
    component: () => import("/opt/render/project/src/apps/frontend/pages/terms-and-conditions.vue").then(m => m.default || m)
  }
]