export enum Locale {
    EN = 'en',
    IT = 'it',
}

const LOCALE_ISO_MAP = {
    [Locale.EN]: 'en-GB',
    [Locale.IT]: 'it-IT',
};

export const locales = Object.values(Locale).map(code => ({ code, iso: LOCALE_ISO_MAP[code] }));

export const defaultLocale = Locale.EN;
